<template>
  <div class="index">
    <div class="index_logo">
      <img src="../assets/image/index_logo.png" />
    </div>
    <div class="banner">
      <van-swipe :autoplay="3000" loop>
        <van-swipe-item v-for="item in images" :key="item.id">
          <img :src="item.ad_code" @click="handleTo(item.ad_link)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="information">
      <div class="imgs">
        <!-- <img src="../assets/image/laba.svg" /> -->
        <span class="text">新闻简报</span>
      </div>
      <div class="right">
        <van-swipe
          style="
            height: 12px;
            font-size: 12px;
            margin-left: 42px;
            margin-top: 15px;
          "
          :autoplay="3000"
          vertical
          :show-indicators="false"
          loop
        >
          <van-swipe-item v-for="item in details" :key="item.id">{{
            item.log_info
          }}</van-swipe-item>
          <!-- <van-swipe-item >王大明</van-swipe-item> -->
          <!-- <van-swipe-item >王大明</van-swipe-item> -->
        </van-swipe>
      </div>
      <!-- <div class="see_detail">查看详情</div> -->
    </div>
    <!-- 热销推荐 -->
    <div class="recommend">
      <div class="top">
        <div>
          <span class="line"></span>
          <span>热销推荐</span>
        </div>
        <div class="more" @click="handleMore">更多>></div>
      </div>
      <div
        class="content"
        v-for="item in contentList"
        :key="item.goods_id"
        @click="handleDetail(item.goods_id)"
      >
        <div class="content_left">
          <img :src="item.original_img" alt />
        </div>
        <div class="content_right clearfix">
          <div class="right_title">{{ item.goods_name }}</div>
          <div class="right_detail">{{ item.goods_remark }}</div>
          <div class="price">
            <span class="price_text">{{ item.shop_price }}</span>
            <span>/{{ item.spu }}</span>
          </div>
          <!-- <div class="buy_now fr" >立即兑换</div> -->
          <div class="buy_now fr">{{ item.btn }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [],
      details: [],
      contentList: [],
    };
  },
  methods: {
    handleTo(e) {
      window.location.href = e;
    },
    handleMore() {
      this.$router.push({
        path: "/integral/index",
      });
    },
    handleDetail(id) {
      if (sessionStorage.getItem("mobile_validated") == 0) {
        this.$router.push({
          path: "/login",
        });
      } else if (sessionStorage.getItem("info_validated") == 0) {
        this.$router.push({
          path: "/information",
        });
      } else {
        this.$router.push({
          path: "/detail/index",
          query: {
            id: id,
          },
        });
      }
    },
    async handleGetBanner() {
      const data = await this.$api.getBanner();
      if (data.data.code === 1) {
        this.images = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetInfo() {
      const data = await this.$api.getInfo();
      if (data.data.code === 1) {
        this.details = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetBestList() {
      const data = await this.$api.getBestList();
      if (data.data.code === 1) {
        this.contentList = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data);
    },
    async GetPerfect() {
      const data = await this.$api.Perfect();
      if (data.data.code === 1) {
        sessionStorage.setItem(
          "mobile_validated",
          data.data.data.mobile_validated
        ); //0,未绑定，1，已绑定手机
        sessionStorage.setItem("info_validated", data.data.data.info_validated); //0,未完善，1，已完善信息
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data, "信息绑定");
    },
  },
  mounted() {
    this.GetPerfect();
    this.handleGetBanner();
    this.handleGetInfo();
    this.handleGetBestList();
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 375px;
  height: 100%;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
  padding-bottom: 50px;
  .banner {
    width: 375px;
    height: 194px;
    padding: 12px;
    box-sizing: border-box;
    img {
      width: 351px;
      height: 170px;
      border-radius: 8px;
    }
  }
  .information {
    width: 375px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 22px;
    margin: 0 auto;
    margin-top: 3px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    font-size: 12px;
    // justify-content: space-between;
    line-height: 12px;
    align-items: center;
    .imgs {
      width: 80px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-image: url("../assets/image/news_background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      img {
        width: 17px;
        height: 17px;
        margin-right: 4px;
      }
      .text {
        display: inline-block;
        // width: 40px;
        height: 10px;
        font-size: 10px;
        color: #ffffff;
        line-height: 4px;
      }
    }
    .right {
      // width: 545px;
      flex:1;
      height: 40px;
      overflow: hidden;

      background-image: url("../assets/image/news_background_right.png");
    }
    // .see_detail {
    //   width: 37px;
    //   height: 10px;
    //   border: 1px solid rgba(253, 42, 42, 1);
    //   font-size: 7px;
    //   color: rgba(253, 42, 42, 1);
    //   line-height: 7px;
    //   text-align: center;
    // }
  }
  .recommend {
    width: 375px;
    height: auto;
    background: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
    .top {
      width: 100%;

      padding: 15px 15px 0 12px;
      box-sizing: border-box;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(102, 102, 102, 1);
      line-height: 18px;
      display: flex;
      justify-content: space-between;
      .line {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #0e3592;
        border-radius: 2px;
        margin-right: 5px;
      }
      .more {
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
        line-height: 12px;
        font-weight: 400;
        margin-top: 4px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .content {
      width: 351px;
      margin: 0 auto;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      padding: 14px 0;
      box-sizing: border-box;
      .content_left {
        width: 122px;
        height: 122px;
        margin-right: 11px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .content_right {
        width: 230px;
        .right_title {
          width: 200px;
          font-size: 16px;
          height: 16px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .right_detail {
          width: 200px;
          height: 40px;
          font-size: 12px;
          color: rgba(153, 153, 153, 1);
          line-height: 20px;
          margin-top: 15px;
          overflow: hidden;
        }
        .price {
          margin-top: 15px;
          font-size: 12px;
          color: #666;
          font-weight: 500;
          line-height: 12px;
          .price_text {
            color: #fd2a2a;
          }
        }
        .buy_now {
          width: 70px;
          height: 25px;
          background: #0e3592;
          border-radius: 15px;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          text-align: center;
          background-image: url("~@/assets/image/btn_img.png");
          background-size: 70%;
          background-repeat: no-repeat;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
}
</style>